<template>
  <div class="animated fadeIn">
    <b-container>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <h5 slot="header" class="mb-0">
          <b-button
            size="sm"
            :to="{
              name: 'admin.customer-loan-accounts.detail',
              params: { id: this.$route.params.id },
            }"
            class="mr-2"
            ><i class="fa fa-chevron-left"></i> Буцах</b-button
          >
        </h5>
        <b-form @submit.stop.prevent="saveData">
          <h4 class="mb-0">Санхүүгийн мэдээлэл</h4>
          <hr class="mt-2" />

          <b-row>
            <b-col>
              <b-form-group
                id="input-group-type"
                label="Орлогын төрөл"
                label-for="input-type"
              >
                <b-select v-model="form.incomeType" :options="income_type_list">
                  <template #first>
                  <b-form-select-option :value="null" disabled>-- Сонгоно уу --</b-form-select-option>
                </template>
                </b-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-ownership"
                label="Салбар"
                label-for="input-ownership"
              >
              <b-select v-model="form.branch" :options="branch_list" :disabled="!form.incomeType">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Сонгоно уу --</b-form-select-option>
                </template>
              </b-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-ownership"
                label="Дэд салбар"
                label-for="input-ownership"
              >
              <b-select v-model="form.subBranch" :options="sub_branch_list" :disabled="(!form.incomeType || !form.branch)">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Сонгоно уу --</b-form-select-option>
                </template>
              </b-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-square"
                label="Өр орлогын харьцаа"
                label-for="input-square"
              >
                <money
                  id="input-factory_year"
                  class="form-control"
                  v-model="form.debtRatio"
                  type="text"
                  required
                  placeholder="Бичнэ үү"
                  suffix=""
                  thousands=""
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-square"
                label="Сарын орлого"
                label-for="input-square"
              >
                <money
                  id="input-factory_year"
                  class="form-control"
                  v-model="form.incomeMonthly"
                  type="text"
                  required
                  placeholder="Бичнэ үү"
                  suffix=""
                  thousands="'"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-ownership"
                label="Ипотектэй эсэх"
                label-for="input-ownership"
              >
              <b-select v-model="form.isIpotek" :options="ipotek_list"></b-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button type="submit" variant="success">Хадгалах</b-button>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  name: "new-loan-finance",
  data: function() {
    return {
      isLoading: false,
      types: [],
      form: {
        id: 0,
        incomeType: null,
        branch: null,
        subBranch: null,
        debtRatio: 0.0,
        incomeMonthly: 0,
        isIpotek: false,
      },
      income_type_list: [],
      branch_list: [],
      sub_branch_list: [],
      ipotek_list: [
        { value: true, text: 'Тийм' },
        { value: false, text: 'Үгүй' }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    'form.incomeType': {
      handler(incomeType) {
        console.log(incomeType)
        if(incomeType != undefined) {
          this.$data.branch_list = this.getTypeList(incomeType)
          // this.$data.form.branch = null
          // this.$data.form.subBranch = null
        }
      },
      deep: true,
    },
    'form.branch': {
      handler(branch) {
        if(branch != undefined) {
          this.$data.sub_branch_list = this.getTypeList(branch)
          // this.$data.form.subBranch = null
        }
      },
      deep: true,
    },
  },
  methods: {
    initData: function() {
      this.isLoading = true;

      this.$data.types = []
        // this.$http
        // .get(
        //   this.$config.API_URL + "LoanWebService/get_loan_finance_list",
        //   {
        //     headers: this.$store.getters.httpHeader,
        //     emulateJSON: true,
        //   }
        // )
        axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/get_loan_finance_list",
            {
              headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
              },
        })
        .then(
          (response) => {
            this.isLoading = false;
            
            if(response.data.success) {
              this.$data.types = response.data.types
            // if(response.body.success) {
            //   this.$data.types = response.body.types
              this.$data.income_type_list = this.getTypeList(null)
            } 
            axios
            .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/get_finance",
                {
                  id: this.$route.params.id
                },
                {
                  headers: {
                    ...this.$store.getters.httpHeader, // Include your custom headers
                    "Content-Type": "application/json", // Ensure JSON content type
                  },
            })
            .then(
              (response) => {
                console.log(response)
                this.isLoading = false;
                this.$data.form = response.data.data;
                // this.$data.form = response.body;
              },
              (response) => {
                this.isLoading = false;
              }
            );
          },
          (response) => {
            this.isLoading = false;
          }
        );
      // this.$http
      //   .get(
      //     this.$config.API_URL + "LoanWebService/get_finance",
      //     {
      //       params: { id: this.$route.params.id },
      //       headers: this.$store.getters.httpHeader,
      //       emulateJSON: true,
      //     }
      //   )
      // axios
      //   .post(this.$config.TEST_NUMUR_MS_ADMIN_URL + "loan/get_finance",
      //       {
      //         id: this.$route.params.id
      //       },
      //       {
      //         headers: {
      //           ...this.$store.getters.httpHeader, // Include your custom headers
      //           "Content-Type": "application/json", // Ensure JSON content type
      //         },
      //   })
      //   .then(
      //     (response) => {
      //       console.log(response)
      //       this.isLoading = false;
      //       this.$data.form = response.data.data;
      //       // this.$data.form = response.body;
      //     },
      //     (response) => {
      //       this.isLoading = false;
      //     }
      //   );
    },
    getTypeList: function(parentId) {
      let result = []
      for(var i in this.$data.types) {
        let item = this.$data.types[i]
        if(parentId) {
          if(item.parentId == parentId) {
            result.push({
              value: item.id,
              text: item.name
            })
          }
        } else {
          if(item.parentId == null) {
          // if(!item.hasOwnProperty("parentId")) {
            result.push({
              value: item.id,
              text: item.name
            })
          }
        }
      }
      return result
    },
    saveData: function() {
      this.isLoading = true;
      console.log(this.$data.form)
      // this.$http
      //   .post(
      //     this.$config.API_URL + "LoanWebService/create_finance",
      //     {
      //       loanAccountId: this.$route.params.id,
      //       id: this.$data.form.id,
      //       data: JSON.stringify(this.$data.form),
      //     },
      //     {
      //       headers: this.$store.getters.httpHeader,
      //       emulateJSON: true,
      //     }
      //   )
        axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/create_finance",
            {
              loanAccountId: this.$route.params.id,
              id: this.$data.form.id,
              data: this.$data.form
            },
            {
              headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
              },
        })
        .then(
          (response) => {
            this.isLoading = false;
            // if (response.body.success) {
            if (response.data.success) {
              this.$router.push({
                name: "admin.customer-loan-accounts.detail",
                params: { id: this.$route.params.id }, 
              });
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
